<template>
  <div>
    <v-card elevation="0">
      <div class="text-subtitle-2 pa-1 blue--text blue lighten-5">
        契約情報を新しく追加する場合は、店舗管理から行なって下さい。
      </div>
      <v-card-title>
        <v-container fluid>
          <v-row align="center">
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="condition.contract_date"
                label="契約基準日"
                @input="changeCondition"
                type="date"
                hide-details
                dense
              />
            </v-col>
            <v-col cols="12" sm="3">
              <v-select
                :items="users"
                v-model="condition.user_id"
                label="ユーザー名選択"
                @change="changeCondition"
                hide-details
                dense
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" sm="3">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="（キーワード検索）"
                single-line
                hide-details
                clearable
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
      <!-- テーブル -->
      <v-data-table
        :headers="headers"
        :items="desserts"
        :search="search"
        item-key="id"
        :mobile-breakpoint="0"
        :items-per-page="100"
        :footer-props="{
          'items-per-page-options': [100, 200, 400, -1],
          'items-per-page-text': '表示件数',
        }"
      >
        <!-- eslint-disable-next-line -->
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} ～{{ props.pageStop }}件／全{{
            props.itemsLength
          }}件
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.contract="{ item }">
          <v-icon class="pa-1" @click="contractItem(item)">
            mdi-note-edit-outline
          </v-icon>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.end_date="{ item }">
          <span v-if="item.end_date != setting.auto_flag_end_date">
            {{ item.end_date }}
          </span>
          <span v-else>自動更新</span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.plan_name="{ item }">
          <span
            v-for="(plan, index) in item.plan_names"
            :key="index"
            :class="
              item.is_active
                ? plan.code.slice(0, 2) == setting.plan_type_id_basic
                  ? 'plan_name_primary'
                  : 'plan_name_secondary'
                : 'plan_name_delete'
            "
          >
            {{ plan.name }}
          </span>
        </template>
        <!-- eslint-disable-next-line -->
        <template v-slot:item.total_fixed_fee="{ item }">
          <div class="right-input">
            {{ item.total_fixed_fee.toLocaleString() }}
          </div>
        </template>
        <template v-slot:no-data> 表示するデータがありません </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { ref, reactive, toRefs, defineComponent } from "@vue/composition-api";
import { repositoryFactory } from "@/repository/repositoryFactory";
import store from "@/store/index.js";
import setting from "@/common/setting.js";
import utilDate from "@/common/utilDate";
import router from "@/router/index";

export default defineComponent({
  setup(_, ctx) {
    const usersRepository = repositoryFactory.get("users");
    const shopsRepository = repositoryFactory.get("shops");
    const plansRepository = repositoryFactory.get("plans");
    const shopContractsRepository = repositoryFactory.get("shopContracts");
    const refAppBar = ref();
    const state = reactive({
      users: [],
      planList: [],
      search: "",
      headers: [
        {
          text: "契約",
          value: "contract",
          sortable: false,
          align: "center",
          width: 60,
        },
        {
          text: "登録ユーザー名",
          value: "user_name",
          sortable: true,
          class: "td_user_name",
        },
        {
          text: "店舗名",
          value: "shop_name",
          sortable: true,
          class: "td_shop_name",
        },
        {
          text: "プラン",
          value: "plan_name",
          sortable: true,
          class: "td_plan_name",
          width: 400,
        },
        {
          text: "契約開始",
          value: "start_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "契約終了",
          value: "end_date",
          sortable: true,
          class: "td_date",
        },
        {
          text: "月額合計",
          value: "total_fixed_fee",
          sortable: true,
          class: "td_total_fixed_fee",
        },
        {
          text: "コメント・補足",
          value: "description",
          sortable: false,
          class: "td_description",
        },
      ],
      desserts: [], // DataTablesレコード格納用
      condition: {
        contract_date: null,
        user_id: null,
      },
    });

    const editItem = (user) => {
      // ダイアログ開く
      refAppBar.value.action({
        action: "edit",
        value: user.id,
      });
    };

    // 初期化処理(同期処理)
    const init = async () => {
      store.dispatch("loadingIcon/showIcon"); // ローディング表示

      if (state.condition.contract_date == null) {
        state.condition.contract_date = utilDate.getDateFormatLong(new Date());
      }

      state.users.splice(0);
      await usersRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            state.users.push({ value: null, text: "未選択" });
            Object.keys(response.data).forEach(function (key) {
              state.users.push({
                value: response.data[key].id,
                text: response.data[key].name,
              });
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:admin/shop/index.vue/deleteItem usersRepository.admin_list (" +
            error +
            ")"
          );
        });

      // Planリスト取得
      state.planList.splice(0);
      await plansRepository
        .admin_list()
        .then((response) => {
          if (response.data) {
            // データ追加OK
            Object.keys(response.data).forEach(function (key) {
              state.planList.push(response.data[key]);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:dialogComponent.vue/onSubmit templateRepository.create (" +
            error +
            ")"
          );
        });

      await getList();

      store.dispatch("loadingIcon/hideIcon"); // ローディング非表示
    };

    const getList = async () => {
      state.desserts.splice(0);
      await shopContractsRepository
        .admin_list_by_date(state.condition)
        .then((response) => {
          if (response.data) {
            Object.keys(response.data).forEach(function (key) {
              const contract = response.data[key];
              contract.plan_names = [];
              contract.total_fixed_fee = 0;
              for (let i = 0; i < contract.detail.length; i++) {
                const plan = state.planList.find(
                  (plan) => plan.code === contract.detail[i].plan_code
                );
                contract.plan_names.push({
                  code: plan.code,
                  name: plan.name,
                });
                contract.total_fixed_fee +=
                  contract.detail[i].fixed_fee * contract.detail[i].quantity;
              }
              state.desserts.push(contract);
            });
          }
        })
        .catch((error) => {
          throw (
            "ERROR:shop/index.vue/deleteItem shopsRepository.admin_list_by_date (" +
            error +
            ")"
          );
        });
    };

    const changeCondition = async () => {
      // 日付形式でない時、検索しない
      if (state.condition.contract_date != null) {
        const regex = /^\d{4}-\d{2}-\d{2}$/;
        if (!regex.test(state.condition.contract_date)) {
          return false;
        }
      }
      await getList();
    };

    const contractItem = (item) => {
      router.push({
        name: "admin-contract-edit",
        params: {
          shop_id: item.shop_id,
        },
      });
    };

    init();

    const updated = (user_data) => {
      init();
    };

    // 返却オブジェクト定義
    return {
      refAppBar,
      setting,
      ...toRefs(state),
      editItem,
      init,
      updated,
      changeCondition,
      contractItem,
    };
  },
});
</script>
<style scoped>
::v-deep .v-data-table table thead tr th,
::v-deep .v-data-table table tbody tr td {
  padding: 0 6px !important;
}
::v-deep .td_plan_name {
  min-width: 150px;
  width: 200px;
}
::v-deep .td_date,
::v-deep .td_total_fixed_fee {
  min-width: 90px;
  width: 90px;
}
.overflow-text {
  max-width: 200px;
}

.overflow-text::-webkit-scrollbar {
  display: none;
}
.plan_name_primary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #1976d2;
}
.plan_name_secondary {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #5cbbf6;
}
.plan_name_delete {
  line-height: 30px;
  padding: 2px 4px;
  border-radius: 4px;
  margin: 2px;
  color: white;
  background-color: #9e9e9e;
}
</style>
